<template>
  <v-container class="ml-10" align="center">
    <template>
      <v-sheet color="white" align="center" tile>
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="12" sm="12">
            <v-img
              height="550"
              class="white--text align-end pa-2"
              src="../../../assets/BuddhaAirMain.jpg"
              ><v-title class="display-1" style="font-weight: bold"
                >Buddha Air</v-title
              ><br />
              <v-subtitle style="font-weight: bold">
                Jawalakhel, Lalitpur, Nepal</v-subtitle
              >
            </v-img>
          </v-col>
        </v-row>
      </v-sheet>
    </template>
    <template>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card class="pa-5" elevation="5">
            <h1
              class="headline mb-2 primary--text"
              style="border-bottom: 1px solid grey"
            >
              Performance of Airlines
            </h1>
            <template>
              <div id="app">
                <GChart
                  type="ColumnChart"
                  :data="chartData"
                  :options="chartOptions"
                  style="height: 350px"
                />
              </div>
            </template>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="pa-5" elevation="5">
            <h1
              class="headline mb-2 primary--text"
              style="border-bottom: 1px solid grey"
            >
              Top 10 Booking Destination
            </h1>
            <template>
              <div id="chart" ml="10">
                <apexchart
                  type="pie"
                  height="350"
                  width="650"
                  align="left"
                  :options="chartsOptions"
                  :series="series"
                ></apexchart>
              </div>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template>
      <v-row>
        <v-col cols="12" sm="6">
        <v-card color="#232620">
            <v-app-bar color="rgba(0,0,0,0)" flat>
                <v-toolbar-title class="white--text title">Domestic Passenger</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-dialog max-width="1000px" v-model="dialogCreate">
                    <template v-slot:activator="{ on }">
                        <v-chip :success="success"
                                class="black--text clickbtn mb-2 float-right"
                                color="white"
                                dark
                                v-on="on">
                            Add Domestic Passenger
                        </v-chip>
                    </template>
                    <createDivisionForm :success="success"
                                        @formResponse="onResponse" />
                </v-dialog>
                <v-dialog max-width="1000px" v-model="dialogEdit">
                    <editDivisionForm :serviceID="editedData.serviceID"
                                      :success="success"
                                      @formResponse="onResponse"
                                      v-if="dialogEdit" />
                </v-dialog>
                <v-dialog max-width="500px" v-model="dialogDelete">
                    <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
                </v-dialog>
            </v-app-bar>
          <v-data-table :headers="headers" :items="desserts" class="dattab">
            <template v-slot:item.glutenfree="{ item }">
              <v-simple-checkbox
                v-model="item.glutenfree"
                color="cyan"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.actions="">
              <v-icon  @click="editItem(props.row)" class="mr-2" small> {{ svgpencil }}</v-icon>
              <v-icon  @click="deleteItem(props.row)" small> {{ svgdelete }}</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
          <v-card class="pa-5" elevation="5">
            <h1
              class="headline mb-2 primary--text"
              style="border-bottom: 1px solid grey"
            >
              Monthly Performance of Airlines 
            </h1>
            <template>
              <div id="app">
                <GChart
                  type="ColumnChart"
                  :data="monthlyData"
                  :options="chartOptions"
                  style="height: 350px"
                />
              </div>
            </template>
          </v-card>
          
        </v-col>
      </v-row>
      
    </template>
  </v-container>
</template>
<script>
    import { mdiPencil, mdiDelete } from "@mdi/js";
    import axios from "axios";
    import createDivisionForm from "../DomesticPassenger/create.vue";


    export default {
        name: "BuddhaAir",
        components: {
            createDivisionForm
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {

                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        data() {
            return {
                svgpencil: mdiPencil,
                svgdelete: mdiDelete,
                dialogCreate: false,
                slides: ["", "", "", ""],
                chartData: [
                    ["Flights", "Incoming", "Outgoing"],
                    ["Nepal Airlines", 15000, 12000],
                    ["Buddha Air", 14230, 12000],
                    ["Yeti Airlines", 13660, 15000],
                    ["Guna Airlines", 7030, 9020],
                    ["Himalaya Airlines", 12025, 11020],
                    ["Saurya Airlines", 3040, 3504],
                    ["Shree Airlines", 2100, 1420],
                    ["Sita Air", 10000, 12300],
                ],
                monthlyData: [
                    ["Month", "Passenger"],
                    ["Jan", 2000],
                    ["Feb", 2170],
                    ["Mar", 2060],
                    ["Apr", 3030],
                    ["May", 40250],
                    ["Jun", 40400],
                    ["Jul", 50000],
                    ["Aug", 58000],
                    ["Sep", 68000],
                    ["Oct", 7000],
                    ["Nov", 58000],
                    ["Dec", 68000],
                ],
                series: [
                    56090, 44961, 30200, 44336, 20350, 56210, 44219, 30212, 44320, 20120, 1200, 2130, 1239, 1236, 2810
                ],
                chartOptions: {
                    chart: {
                        title: "Company Performance",
                        subtitle: "Sales, Expenses, and Profit: 2014-2020",
                    },
                },
                chartsOptions: {
                    chart: {
                        width: 800,
                        type: "donut",
                    },
                    labels: [
                        "Pokhara",
                        "Kathmandu",
                        "Rajbiraj",
                        "Bhairahawa",
                        "Biratnagar",
                        "Bharatpur",
                        "Bhadrapur",
                        "Dhangadhi",
                        "Tumlingtar",
                        "Nepalgunj",
                        "Surkhet",
                        "Janakpur",
                        "Simara",
                        "Varanasi"
                    ],
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 500,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
                desserts: [
                    {
                        sno: 1,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 60,
                        departureTime: "08:30 A.M",
                        arrivalTime: "10:00 A.M"
                    },
                    {
                        sno: 2,
                        airportName: "Yeti Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 40,
                        departureTime: "02:15 P.M",
                        arrivalTime: "3:45 P.M"
                    },
                    {
                        sno: 3,
                        airportName: "Guna Airlines",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 20,
                        departureTime: "04:45 P.M",
                        arrivalTime: "06:25 P.M"
                    },
                    {
                        sno: 4,
                        airportName: "Saurya Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 15,
                        departureTime: "06:25 P.M",
                        arrivalTime: "08:05 P.M"
                    },
                    {
                        sno: 5,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 20,
                        departureTime: "07:00 P.M",
                        arrivalTime: "08:30 P.M"
                    },
                ],
                headers: [
                    { text: "S.No", value: "sno" },
                    {
                        text: "Airport Name",
                        align: "start",
                        sortable: false,
                        value: "airportName",
                    },
                    { text: "Arrival Departure", value: "arrivalDeparture" },
                    { text: "Number of Person", value: "numberOfPerson" },
                    { text: "Departure Time", value: "departureTime" },
                    { text: "Arrival Time", value: "arrivalTime" },
                    { text: "Actions", value: "actions", sortable: false },
                ],

            };

        },
        methods: {
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            editItem(props) {
                this.editedData.serviceID = props.id;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Airport/DeleteDomesticPassengerByIDAsync/" + props.id;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                };
                axios.post("Airport/GetDomesticPassengerDetailListAsync", param).then(response => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                })
                    .catch(err => {
                        console.log(err)
                    });

            }
        }
    };
</script>
