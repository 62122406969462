<template>
    <v-card>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_domestic_passenger')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-row>

                        <v-col cols="12" md="6">
                            <v-autocomplete :items="airlineList"
                                            item-value="id"
                                            dense
                                            outlined
                                            item-text="value"
                                            v-model="editedData.airlineName"
                                            :label="$t('airline_name')"
                                            required></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-autocomplete :items="['Arrival','Departure']"
                                            dense
                                            outlined
                                            v-model="editedData.arrivalDeparture"
                                            :label="$t('arrival/departure')"
                                            required></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6">

                            <v-text-field v-model="editedData.numberOfPerson"
                                          dense
                                            outlined
                                          :label="$t('number_of_person')"
                                          type="number"
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">

                            <v-text-field v-model="editedData.flightNumber"
                                          dense
                                            outlined
                                          :label="$t('flight_number')"
                                          type="string"
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-menu v-model="dateMenu"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="editedData.arrivalDepartureDate"
                                                  :label="$t('arrival_departure_date')"
                                                  clearable
                                                  dense
                                            outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="editedData.arrivalDepartureDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-menu v-model="timeMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="editedData.arrivalDepartureTime"
                                                  :label="$t('arrival_departure_time')"
                                                  clearable
                                                  dense
                                            outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-time-picker v-model="editedData.arrivalDepartureTime">

                                </v-time-picker>
                            </v-menu>
                        </v-col>

                    </v-row>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    // import { required } from "vuelidate/lib/validators";
    import axios from "axios";


    export default {
        name: "createAirportForm",
        props: ["success"],
        computed: {},
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                airlineList: [],
                state: [],
                dateMenu: false,
                timeMenu: false,
                province: [],
                show: false,
                updated: false,
                editedData: {},
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {},
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                axios.get("Airport/DDLAirlineListAsync").then(response => {
                    this.airlineList = response.data;
                }).catch(error => console.log(error.response))
            },
            submit() {
                this.$v.$touch();
               
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    this.editedData.numberOfPerson = parseInt(this.editedData.numberOfPerson)
                    axios.post("Airport/InsertIntoDomesticPassengerAsync", this.editedData).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {}
                            this.servicesData.data = response.data
                            this.servicesData.message = "Passenger added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                            
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.VehicleType = "";
                this.editedData.VehicleCategory = "";
            }
        }
    };
</script>

<style scoped>
</style>